.pagination .MuiPaginationItem-page.Mui-selected {
  background-color: var(--primColor);
  color: white;
  border: none;
}
.pagination {
  margin: 10px 0;
  padding: 0 8px;
}
.pagination .MuiPaginationItem-page.Mui-selected:hover,
.pagination .MuiPaginationItem-page:hover {
  background-color: var(--primColor);
  color: white;
}
