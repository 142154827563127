.post-listing-wrapper {
  background: #fff;
  padding-bottom: 75px;
  margin-bottom: -75px;
}
.pl-banner {
  width: 100%;
  max-width: 1200px;
  position: relative;
  height: 325px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 40px;
}

.pl-banner .plb-img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: center;
}

.plb-head {
  font-weight: 900;
  font-size: 44px;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 110;
}

.pl-form-wrapper {
  width: calc(100% - 30px);
  max-width: 450px;
  margin: 0 auto;
}

.pl-form-wrapper textarea {
  resize: none;
}

.pl-label,
.pl-form-wrapper .MuiInputLabel-formControl {
  font-size: 16px;
  line-height: 21px;
  text-align: left;
  color: #595959;
  font-family: "Avenir-Medium";
}

.cif-wrapper {
  position: relative;
}

.cif-wrapper input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  border-radius: 4px;
}

.cif-wrapper .cif-label {
  width: 100%;
  height: 56px;
  border-radius: 4px;
  background: #f2f5f6;
  border: 1px solid #c8cfd3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.cif-label .fa {
  margin-right: 15px;
}

.pl-form-wrapper .form-group {
  margin-bottom: 25px;
}

.cp-head {
  font-size: 36px;
  text-align: center;
  color: var(--primColor);
  padding: 35px 0 5px;
}

.cp-stext {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #595959;
  max-width: 825px;
  margin: 0 auto 30px;
}

.choose-planner-wrpper .card {
  height: 769px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  border-color: #fff;
  position: relative;
  padding-bottom: 65px;
}

.cp-img {
  height: 140px;
  object-fit: contain;
  object-position: center;
  margin: 20px auto;
  display: block;
}

.cc-title {
  font-size: 28px;
  text-align: center;
  color: var(--primColor);
  max-width: 225px;
  margin: 0 auto;
  font-family: "Avenir-Medium";
  font-weight: 600;
}

.cc-price {
  margin: 5px auto;
  font-size: 32px;
  text-align: center;
  color: #000;
  font-family: "Avenir-Medium";
  font-weight: 600;
}

.cc-note {
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #000;
  max-width: calc(100% - 30px);
  margin: 10px auto;
  font-family: "Avenir-Medium";
}

.cc-options {
  padding: 0 15px;
  max-width: calc(100% - 50px);
  margin: 0 auto;
  font-family: "Avenir-Medium";
}

.cc-options p {
  font-family: "Avenir-Book";
  font-weight: 600;
}

.cc-options ul {
  list-style: none;
  padding: 0 0 0 15px;
}

.cc-options p,
.cc-options li {
  font-size: 14px;
  color: #000;
  text-transform: capitalize;
  margin: 0 0 5px;
}

.choose-planner-wrpper .card .btn-primary {
  width: 100%;
  height: 65px;
  position: absolute;
  bottom: 0;
  left: 0;
  font-weight: 600;
  text-transform: none;
  font-family: "Avenir-Light";
}

.yp-head {
  border-bottom: 1px solid #707070;
  font-size: 32px;
  padding-bottom: 30px;
  color: var(--primColor);
  margin: 0 0 20px;
}

.sc-media {
  align-items: center;
}

.sc-media .media-body {
  padding: 20px;
}

.sc-media .sc-img {
  width: 266px;
  height: 240px;
  object-fit: cover;
  object-position: center;
}

.cl-head {
  font-weight: 900;
  font-size: 32px;
  line-height: 22px;
  color: #000;
  margin-bottom: 30px;
}

.sc-media .float-left {
  min-width: 50%;
}

.sc-wrapper .MuiFormControlLabel-root {
  margin-left: 0;
}

.sc-gbox {
  padding: 16px;
  background: #f5f5f5;
}

.sc-gbox {
  width: 100%;
}

.os-wrap {
  margin: 0px 0 25px;
  font-weight: 900;
  font-size: 22px;
  text-align: left;
  color: #000;
}

.sc-white-box {
  padding: 20px;
  background: #ffffff;
}

.sc-summary-text {
  font-size: 18px;
  color: #000;
  margin: 0 0 15px;
}

.sc-white-box hr {
  border-color: #000;
}

.iframeStyle {
  width: 100%;
  height: 450px;
  border: none;
}

.pl-form-wrapper .makeStyles-root-1 {
  margin: 0 !important;
}

@media (min-width: 768px) {
  .cp-data-toggle {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .syh-row {
    margin: 0 -5px;
  }

  .syh-row > div {
    padding: 0 5px;
  }

  .cp-img {
    max-width: 90%;
  }

  .cc-options,
  .cc-note {
    max-width: 95%;
  }
  .cc-options p,
  .cc-options li,
  .cc-note {
    font-size: 13px;
  }

  .sc-wrapper .MuiFormControlLabel-root {
    margin: 0;
  }

  .sc-wrapper .MuiFormControlLabel-root .MuiTypography-root {
    font-size: 14px;
  }
}

@media (max-width: 1023px) {
  .sc-media .sc-img {
    width: 166px;
    height: 140px;
  }

  .sc-media {
    align-items: flex-start;
  }

  .yp-head {
    font-size: 24px;
    padding: 0 0 15px;
  }

  .sc-media .cc-options {
    max-width: 100%;
    padding: 0;
  }
}

@media (max-width: 767px) {
  .cp-head {
    font-size: 24px;
    padding: 15px 0 0;
  }

  .cp-stext {
    font-size: 12px;
    line-height: 14px;
    margin: 0 0 15px;
  }

  .cp-img-wrapper {
    position: relative;
    padding-left: 80px;
    padding-right: 40px;
  }

  .cp-img {
    height: 40px;
    max-width: 60px;
    object-position: top;
    position: absolute;
    left: 10px;
    top: 0;
    margin: 10px 0;
  }

  .cp-data-toggle {
    position: absolute;
    font-size: 24px;
    right: 10px;
    top: 10px;
  }

  .cc-title,
  .cc-price {
    text-align: left;
  }

  .cc-title {
    font-size: 16px;
  }

  .cc-price {
    font-size: 18px;
  }

  .choose-planner-wrpper .card {
    padding: 10px 10px 55px;
    height: auto;
  }

  .choose-planner-wrpper .card .btn-primary {
    height: 40px;
    font-size: 14px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cp-content.hide {
    display: none;
  }

  .pl-banner {
    height: 150px;
    margin: 0 auto 20px;
  }

  .plb-head {
    font-size: 24px;
  }

  .float-right.cc-price {
    float: none !important;
  }
}

@media (max-width: 479px) {
  .sc-media {
    flex-wrap: wrap;
  }

  .sc-media .media-left {
    width: 100%;
  }

  .sc-media .media-body {
    padding: 15px 0;
  }

  .sc-media .sc-img {
    width: 100%;
  }
}
