.search-wrapper {
  display: flex;
  height: auto;
  padding-top: 0;
  margin-bottom: -60px;
  background: #fff;
}

.search-wrapper .search-left {
  flex: 1;
  position: sticky;
  top: 0;
  height: 90vh;
}

.search-wrapper .search-right {
  width: 580px;
  padding: 0 8px;
  overflow: auto;
}

.search-bottom {
  padding: 5px 15px;
  font-family: "Avenir-Medium";
  font-weight: 900;
  font-size: 14px;
  line-height: 21px;
  color: #000;
  position: absolute;
  bottom: 12px;
  left: 12px;
  background: white;
  z-index: 99;
  border-radius: 4px;
  width: max-content;
  height: 30px;
}

.sr-top {
  background: #f8fafb;
  padding: 14px 10px;
}

.sr-top-head {
  font-size: 24px;
  color: #000;
  line-height: 32.78px;
}

.no-results {
  font-size: 14px;
  line-height: 21px;
  color: #595959;
  margin-right: 20px;
  white-space: pre;
}

.fs-link-btn {
  background: #f2f5f7;
  border: 1px solid #f2f5f7;
  width: auto;
  height: 40px;
  padding: 10px;
  font-family: "Avenir-Medium";
  font-size: 12px;
  text-align: center;
  color: var(--primColor);
  display: block;
  min-width: 75px;
  text-transform: uppercase;
  border-radius: 4px;
  white-space: pre;
}

.fs-link-btn:focus,
.fs-link-btn:hover {
  background: #fff;
  border: 1px solid var(--primColor);
  text-decoration: none;
}

.search-filters-wrap {
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: space-between;
}

.search-filters-wrap > div {
  margin: 8px 0;
  width: calc(25% - 4.5px);
}

.search-filters-wrap select.form-control {
  font-family: Avenir-Medium;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #9faebb;
  height: 40px;
  padding: 10px 5px;
  min-width: 92px;
}

.sg-wrapper {
  padding: 0 8px;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}

.sg-wrapper > div {
  width: calc(50% - 8px);
}

.mapboxgl-marker {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid black;
  background-color: #9faebb;
}

.mapboxgl-map {
  max-height: calc(100% - 50px);
}

.searchFilter {
  padding: 30px 45px;
  min-width: 20vw;
}

.searchFilter h5 {
  font-size: 16px;
  font-weight: 700;
  margin-top: 25px;
}

.searchFilter .MuiButtonBase-root.MuiIconButton-root {
  padding: 0;
}

.searchFilter h2 {
  font-weight: 800;
}

.searchFilter .MuiFormControlLabel-root {
  margin: 0;
}

.search-filters-wrap .btn {
  font-size: 12px;
  line-height: 20px;
  width: 100%;
}

.ss-wrap {
  position: relative;
}

.ss-wrap > span {
  width: 50px;
  display: inline-block;
  vertical-align: middle;
  font-weight: 800;
}

.ss-wrap .form-control {
  color: var(--primColor) !important;
  font-weight: 800;
}

.search-filters-wrap .customInput .MuiTextField-root {
  margin-bottom: 0;
}

.search-filters-wrap .customInput .MuiInputBase-root {
  height: 40px;
}

.search-filters-wrap .customInput .MuiTypography-colorTextSecondary {
  z-index: 1;
}

.search-filters-wrap .customInput .MuiOutlinedInput-root input {
  padding-left: 5px !important;
  height: 40px !important;
}

.search-filters-wrap .MuiOutlinedInput-adornedStart {
  padding-left: 10px;
}

.clsContainer {
  display: none;
}

.sfInner .MuiTypography-colorTextSecondary {
  z-index: 1;
  font-size: 13px;
}

.sfInner .MuiOutlinedInput-adornedEnd {
  padding-right: 5px;
}

.filterBadge {
  background: var(--primColor);
  border-radius: 50%;
  color: #fff;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
}

.mobile-sg-wrapper {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .map-sort {
    display: none;
  }

  .filterPhoneDrawer {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .search-wrapper {
    flex-wrap: wrap;
    overflow: inherit;
    padding-top: 108px;
    height: auto;
    background: #f2f5f7;
    position: relative;
    margin: 0;
    min-height: calc(100vh - 225px);
  }

  .srt-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
  }

  .search-wrapper .search-right,
  .search-wrapper .search-left {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  .search-wrapper .search-left {
    height: 300px;
    flex: 0 0 100%;
  }

  .sg-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .sg-wrapper > div.card {
    width: 33%;
  }

  .no-views {
    float: none;
    display: block;
  }

  .search-filters-wrap {
    flex-wrap: wrap;
    padding-top: 40px;
  }

  .search-filters-wrap .mblSrchFrm {
    display: none;
  }

  .search-filters-wrap > div {
    width: 33%;
  }

  .search-bottom {
    display: none;
  }

  .search-left.map-hide,
  .search-right.map-hide .sg-wrapper {
    display: none;
  }

  .search-wrapper .search-right.map-hide {
    padding-bottom: 0;
  }

  .map-sort {
    display: flex;
    max-width: 215px;
    margin: 0 auto;
    align-items: center;
    background: #fff;
    border-radius: 4px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 30px;
    z-index: 99;
  }

  .map-toggle {
    width: 83px;
    height: 35px;
    padding: 7px;
    text-align: center;
    cursor: pointer;
  }

  .search-right .map-sort > div {
    min-width: 83px;
    width: auto;
    position: relative;
  }

  .search-right .map-sort > div.selectWrapper {
    min-width: 132px;
    border-left: 1px solid #000;
  }

  .ss-wrap > span.sort-icon {
    position: absolute;
    width: 20px;
    height: 34px;
    padding: 8px 5px;
  }

  .map-sort .selectWrapper .form-control {
    padding: 0 5px 0 25px;
    height: 35px;
    border-color: transparent;
  }

  .map-sort .selectWrapper::after {
    display: none;
  }

  .search-wrapper .search-right {
    padding-bottom: 45px;
  }

  .search-filters-wrap .btn {
    font-size: 12px;
    line-height: 20px;
    width: 100%;
    min-width: 100%;
    height: 35px;
    padding: 8px;
  }

  .sr-top {
    padding: 9px 10px 5px;
  }
  .sr-top-head {
    font-size: 18px;
  }

  .search-wrapper .search-left {
    height: 500px;
    flex: 0 0 100%;
    position: relative;
  }

  .map-sort.list-toggle {
    max-width: 83px;
  }

  .map-sort.bottom {
    position: absolute;
    bottom: 5px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .search-filters-wrap {
    justify-content: space-between;
  }

  .search-wrapper .grid-card .nf-list > div {
    display: inline-block;
  }

  .search-wrapper .grid-card .nf-list > div + div {
    margin-top: 0;
  }
  .search-filters-wrap > div {
    margin: 4px 0;
  }

  .sg-wrapper > div.card {
    width: calc(33.3% - 2%);
    margin: 1%;
  }
}

@media (max-width: 767px) {
  .suitable {
    margin: 30px 0px !important;
  }
  .search-filters-wrap {
    flex-wrap: wrap;
  }

  .search-filters-wrap > div {
    width: 30%;
  }

  .search-filters-wrap > div:first-child {
    width: 100%;
    margin: 0;
  }

  .sg-wrapper > div.card {
    width: 100%;
  }

  .clsContainer {
    display: block;
  }

  .clsContainer .clsDrBtn .MuiButton-label {
    color: var(--primColor);
    text-transform: none;
  }

  .searchFilter {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 70px;
  }
}

@media (min-width: 540px) and (max-width: 767px) {
  .sg-wrapper > div.card {
    width: 49%;
  }
}

@media (max-width: 375px) {
  .search-wrapper {
    padding-top: 150px;
  }
}

@media (max-width: 374px) {
  .search-filters-wrap > div {
    width: 28%;
  }

  .search-filters-wrap > div:first-child {
    width: 42%;
    margin: 0;
  }

  .search-filters-wrap > div:nth-child(4),
  .search-filters-wrap > div:nth-child(5) {
    width: 48%;
    margin: 0;
  }
}
