.auction-card {
  max-height: 290px;
  background: #fff;
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: row;
  border: none;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
  padding: 16px 8px;
}

.auction-card img {
  width: 100%;
  height: 198px;
  max-height: 198px;
  min-height: 198px;
  border-radius: 10px;
}

.cardCnt {
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow: auto;
  padding: 2px 4px;
}

.left {
  flex: 0 0 65%;
}

.right {
  flex: 0 0 35%;
  display: flex;
  flex-direction: column;
}

.timer {
  /* height: 25px; */
  border-radius: 3px;
  background: #fff;
  font-family: "Avenir-Medium";
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-align: center;
  color: #000000;
  padding: 2px 5px;
  /* float: right; */
  width: 100%;
}
.body {
  flex: 1;
  background-color: rgb(244, 244, 244);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.body-row {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}
.label-bold {
  font-family: "Avenir-Heavy";
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #000000;
}
.label {
  font-family: "Avenir-Book";
  font-size: 12px;
  text-align: left;
  color: #505d68;
}
.location-title {
  height: 25px;
  border-radius: 3px;
  background: #fff;
  font-family: "Avenir-Medium";
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-align: left;
  color: #000000;
  padding: 2px 10px;
  float: right;
  width: 100%;
}

.btn-primary {
  padding: 10px 20px;
  border-radius: 15px;
  width: 100%;
}
