.bl-media {
  height: 112px;
  background: #fff;
  margin-bottom: 10px;
  width: 100%;
  box-shadow: 0 4px 14px 0 rgb(0 0 0 / 6%);
  border-radius: 4px;
  position: relative;
  align-items: center;
  overflow: hidden;
}

.bl-media .media-left {
  width: 182px;
  height: 112px;
  position: relative;
}

.bl-media .media-left img {
  width: 182px;
  height: 112px;
  object-fit: cover;
}

.bl-media .media-body {
  padding: 12px 15px;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-align: left;
  color: #000;
  font-family: "Avenir-Medium";
  position: relative;
}

.bl-media .media-body .fw-600 {
  font-weight: 600;
}

.lg-txt {
  color: #9faebb;
  font-family: "Avenir-Book";
}

.bl-price {
  font-size: 24px;
  letter-spacing: 0.02em;
  line-height: 21px;
  color: #000;
  margin-top: 6px;
  font-family: "Avenir-Light";
  font-weight: 700;
}

.nf-list > div {
  display: inline-block;
  vertical-align: middle;
}

.nf-list a {
  font-size: 14px;
  line-height: 21px;
  color: #9faebb !important;
  margin-right: 15px;
}

.current-bid {
  color: #979797;
  font-family: "Avenir-Light";
  font-weight: 400;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .bl-media .media-left {
    width: 200px;
    height: 100%;
  }

  .bl-media .media-left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .bl-media {
    height: 120px;
  }

  .bl-media .media-left {
    width: 140px;
    height: 100%;
  }

  .bl-media .media-left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .lc-cwrap {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
  }

  .lc-cwrap > div {
    width: 49%;
  }

  .bl-price {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .bl-media {
    height: 155px;
  }

  .bl-media .media-left {
    width: 150px;
    height: 100%;
  }

  .bl-media .media-left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .nf-list {
    margin: 5px 0 0;
  }

  .bl-price {
    font-size: 20px;
    margin-top: 10px;
  }

  .bl-media .media-body {
    padding: 10px;
    font-size: 12px;
  }
}

@media (max-width: 479px) {
  .bl-media .media-left {
    width: 100%;
    height: 200px;
  }

  /* .bl-media .media-left img {
    width: 50px;
    height: 50px;
  } */
  .bl-media .media-left .grid-timer {
    left: 0;
    right: initial !important;
  }
  .sm-hide {
    display: none;
  }

  .bl-media {
    height: 300px;
  }
  .media-body {
    width: 100%;
  }
  .prop-address {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .bl-media .nf-list {
    width: 60px;
    position: absolute;
    left: -46px;
    bottom: 0;
  }
  .media {
    align-items: center;
  }
}

.bl-media .media-left .grid-timer {
  position: absolute;
  bottom: 0;
  right: 0;
}

.upcoming-lists {
  position: relative;
  margin-bottom: 50px;
}

.upcoming-lists .tour-scheduled {
  position: absolute;
  width: calc(100% - 181px);
  right: 0;
  bottom: -36px;
  height: 36px;
  display: flex;
  padding: 9px 14px;
  align-items: center;
  background: #f8f8f8;
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / 6%);
  justify-content: space-between;
}

.ts-label {
  font-size: 14px;
  line-height: 22px;
  font-weight: 800;
  min-width: 70%;
}

.tour-scheduled a {
  display: inline-block;
  font-size: 14px;
  color: #000 !important;
  margin-left: 15px;
  text-decoration: underline !important;
  cursor: pointer !important;
}

.upcoming-lists .bl-media {
  z-index: 110;
}

.bl-media .media-body .row {
  align-items: center;
}
