.favoriteCheck {
  border-top-left-radius: 3px;
  position: relative;
  display: inline-block;
}

.favoriteCheck input {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.favoriteCheck label {
  color: #505d68;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  margin: 0;
  font-size: 18px;
  line-height: 1;
}

.favoriteCheck label:hover {
  color: grey;
}

.favoriteCheck label::selection {
  color: none;
  background: transparent;
}

.favoriteCheck label::moz-selection {
  color: none;
  background: transparent;
}

.favoriteCheck input:checked + label {
  color: var(--primColor);
}
