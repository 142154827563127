footer ul {
  padding-left: 0px;
  list-style: none;
}

footer ul li {
  list-style-type: none;
  padding: 4px 0px;
  color: #a1aeb7;
  font-size: 14px;
}

footer a {
  text-decoration: none;
  text-align: left;
  color: #a1aeb7 !important;
  font-weight: 400;
}

footer ul li > a:hover,
footer ul li > a:focus {
  color: var(--primColor) !important;
  text-decoration: none;
}

.footer {
  padding: 45px 0 25px;
  color: #505d68;
}

.footer-bottom {
  background: #f8fafb;
  padding: 6px 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #9faebb;
}

.footer-bottom .list-inline {
  margin: 0;
}

.footer-bottom .list-inline li {
  margin-right: 0;
}

.footer-bottom .list-inline li + li {
  margin-left: 45px;
}

.footer h4 {
  font-weight: normal;
  font-size: 14px;
  text-transform: uppercase;
  color: #505d68;
  font-family: "Avenir-Medium";
}

.nl-wrapper p {
  padding: 10px 0;
  margin: 0;
  color: #a1aeb7;
}

.copy-right {
  padding-top: 4px;
}

.subscribe-wrapper {
  position: relative;
}

.subscribe-wrapper .form-control {
  color: #c2d1d9;
  font-size: 14px;
  height: 40px;
  padding-right: 105px;
}

.subscribe-wrapper .MuiButton-containedPrimary {
  position: absolute;
  height: 40px;
  width: 100px;
  font-size: 13px;
  font-weight: normal;
  right: 0;
  top: 0;
}

/* .news-letter-wrapper a {
  text-decoration: underline;
} */

.footer .footLogo {
  height: 80px;
  width: auto;
  object-fit: contain;
}

.social-icons-list li a {
  font-size: 20px;
}

@media (min-width: 992px) {
  .footer .row > div {
    -ms-flex: 0 0 18%;
    flex: 0 0 18%;
    max-width: 18%;
  }

  .footer .row > div:last-child {
    -ms-flex: 0 0 28%;
    flex: 0 0 28%;
    max-width: 28%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .news-letter-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .nl-wrapper p {
    padding: 0;
  }

  .subscribe-wrapper {
    position: relative;
    width: calc(100% - 250px);
  }
}

@media (max-width: 767px) {
  .footer .footLogo {
    height: 50px;
    width: 100%;
  }
  .footer-bottom .list-inline li + li {
    margin-left: 15px;
  }

  .footer-bottom {
    text-align: center;
    margin-bottom: 50px;
  }

  .social-icons-xs a {
    display: inline-block;
    vertical-align: middle;
    color: #000 !important;
    font-size: 22px;
  }

  .social-icons-xs a + a {
    margin-left: 18px;
  }

  footer ul li.social-icons-xs {
    padding: 0;
    margin-top: 14px;
  }
}
