header {
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  /* position: absolute; */
  position: fixed;
}

.hm-logo {
  max-height: 60px;
  width: 100%;
  object-fit: contain;
}

header .navbar {
  padding: 0 15px;
}

header .navbar-expand-md .navbar-nav > li {
  padding: 10px;
}

header .navbar-expand-md .navbar-nav > li a,
.mobile-menu .dropdown a {
  text-transform: none;
  color: white;
  font-size: 12px;
  font-family: "Avenir-Heavy";
  position: relative;
  cursor: pointer;
}

header .navbar-expand-md .navbar-nav > li a {
  text-decoration: none;
}

header .navbar-expand-md .navbar-nav > li a:after,
.mobile-menu .notify:after {
  border: none;
  content: "";
  width: 100%;
  height: 2px;
  bottom: -20px;
  left: 0;
  background: transparent;
  position: absolute;
}

header .navbar-expand-md .navbar-nav > li:hover a:after,
header .navbar-expand-md .navbar-nav > li a.active:after,
.mobile-menu .notify:hover:after,
.mobile-menu .notify.active:after {
  background: var(--primColor);
}

header .navbar-expand-md .navbar-nav li:hover a.btn-rg:after {
  content: none;
}

header .navbar-expand-md .navbar-nav li .btn-rg,
.mobile-menu .btn-rg {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  border: 1px solid white;
  color: #fff;
  padding: 0;
  border-radius: 3px;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  height: 40px;
  width: 113px;
  line-height: 1em;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Avenir-light";
}

.inner-header {
  background-color: white;
}

header.inner-header .navbar-expand-md .navbar-nav li {
  cursor: pointer;
}

header.inner-header .navbar-expand-md .navbar-nav li a,
.mobile-menu .notify,
.mobile-menu .dropdown-toggle,
.mobile-menu .dropdown a {
  color: #4f4f4f;
}

header.inner-header .navbar-expand-md .navbar-nav li:hover a,
header .navbar-expand-md .navbar-nav li a.active,
.mobile-menu .notify:hover,
.mobile-menu .dropdown-toggle:hover,
.mobile-menu .notify.active {
  color: black;
  cursor: pointer;
  text-decoration: none;
}

.notify {
  position: relative;
}

.blue-circle {
  width: 5px;
  height: 5px;
  position: absolute;
  background: var(--primColor);
  border-radius: 5px;
  right: 1px;
  top: 0px;
}

.fs-16 {
  font-size: 16px;
}

.user-circle {
  width: 18px;
  height: 18px;
  display: inline-block;
  background: #f2f5f7;
  border-radius: 50%;
  color: var(--primColor);
  text-align: center;
  font-size: 10px;
  padding: 1px;
  margin-right: 10px;
}

header .dropdown .dropdown-menu {
  position: absolute;
  right: 0;
  left: inherit;
  min-width: 175px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

header .navbar-expand-md .navbar-nav .dropdown-menu a,
.mobile-menu .dropdown-menu a {
  display: block;
  padding: 8px 8px 8px 12px;
  color: #9faebb;
  border-left: 3px solid transparent;
}

header .navbar-expand-md .navbar-nav .dropdown-menu .buyer-verification-link a,
.mobile-menu .dropdown-menu .buyer-verification-link a {
  display: flex;
}

header .navbar-expand-md .navbar-nav .dropdown-menu a:after,
.mobile-menu .dropdown-menu a:after {
  display: none;
}

header .navbar-expand-md .navbar-nav .dropdown-menu a:hover,
header .navbar-expand-md .navbar-nav .dropdown-menu a:focus,
header .navbar-expand-md .navbar-nav .dropdown-menu a.active,
.mobile-menu.dropdown-menu a:hover,
.mobile-menu .dropdown-menu a:focus,
.mobile-menu .dropdown-menu a.active {
  background: #f2f5f7;
  color: black;
  border-left: 3px solid var(--primColor);
}

.dropdown-menu .cwhite-box {
  margin: 0;
  box-shadow: none;
  background: transparent;
  padding: 0 12px;
}

.dropdown-menu .btn-primary {
  display: none !important;
}

.dbw-box .db-menu {
  padding-bottom: 0;
}

.navbar-nav .MuiBadge-colorPrimary {
  background: var(--primColor);
}

.navbar-nav .MuiBadge-anchorOriginTopRightRectangle {
  top: -5px;
}

.doorsey-no a::after {
  display: none;
}

.doorsey-no a span {
  text-decoration: underline;
}

.nav-mob-btns {
  margin-top: 5rem;
}

.nav-mob-btns .phone-no a {
  color: black;
}

.nav-mob-btns .submit-home a {
  color: #fafafa;
}

.nav-mob-btns .submit-home {
  background-color: #141414;
  width: 100%;
  padding: 10px;
  border: none;
}

.nav-mob-btns .phone-no {
  background-color: #eaeaea;
  border: none;
  padding: 10px;
  width: 100%;
}

.nav-mob-btns {
  padding: 0;
  list-style: none;
}

.nav-mob-btns li {
  padding: 10px;
}

@media (min-width: 992px) {
  .wd-240 {
    min-width: 240px;
  }
}

@media (min-width: 768px) {
  .mobile-menu {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1240px) {
  header .navbar {
    padding: 5px 15px;
  }

  header .navbar-expand-md .navbar-nav li .btn-rg {
    margin-left: 0;
    width: 95px;
  }

  header .navbar-expand-md .navbar-nav > li {
    padding: 10px 5px;
  }

  .main-container {
    padding: 70px 0;
    min-height: calc(100vh - 70px);
  }
}

@media (max-width: 767px) {
  .lo-link-xs {
    padding: 12px;
    background: transparent;
    font-size: 12px;
    line-height: 12px;
    text-align: left;
    color: #505d68;
    display: block;
    cursor: pointer;
    border-left: 3px solid transparent;
    margin-bottom: 2px;
    font-family: "Avenir-Heavy";
    font-weight: 600;
  }

  .mobile-menu {
    display: flex;
    position: absolute;
    right: 40px;
    top: 18px;
  }

  header .container-fluid {
    padding: 0;
  }

  header .navbar {
    padding: 5px 15px;
  }

  .mblNwHdr {
    width: 100%;
  }

  .mblNwHdr .MuiFormControl-root {
    width: 100%;
    margin: 0;
  }

  .navbar-header {
    width: 100%;
    position: relative;
  }

  .navbar-toggler,
  .navbar-toggler-search {
    position: absolute;
    left: 15px;
    height: 20px;
    top: 2px;
    bottom: 0;
    margin: auto;
    padding: 0;
  }

  .navbar-toggler-search {
    left: inherit;
    right: 15px;
    font-size: 1.25rem;
    top: 0px;
    height: 24px;
    color: #000;
  }

  .navbar-brand {
    display: block;
    width: 112px;
    margin: 0 auto;
  }

  .navbar-collapse {
    position: absolute;
    width: 100%;
    background: #fff;
    left: 0;
    top: 55px;
    padding: 0 15px;
    min-height: calc(100vh - 55px);
    overflow: auto;
    max-height: calc(100vh - 55px);
    padding-bottom: 55px;
  }

  header .navbar-expand-md .navbar-nav li .btn-rg {
    margin-left: 0;
  }

  header .navbar-expand-md .navbar-nav > li a {
    color: #9faebb;
  }

  header .navbar-expand-md .navbar-nav li .btn-rg {
    border: 1px solid var(--primColor) !important;
    color: var(--primColor) !important;
  }

  header .navbar-expand-md .navbar-nav > li a:after {
    bottom: -8px;
  }

  .navbar-expand-md .navbar-nav .dropdown:hover .dropdown-menu {
    position: relative;
  }

  .mobile-menu .notify {
    margin: 4px 8px;
  }

  .mobile-menu .dropdown {
    margin: 2px 8px;
  }

  .home-header .mobile-menu .notify,
  .home-header .mobile-menu .dropdown-toggle {
    color: #fff;
  }

  .mobile-menu .btn-rg {
    height: 30px;
    padding: 8px 10px;
    width: 85px;
    margin: 0px 10px;
  }

  .mobile-menu .notify:after {
    bottom: -14px;
  }
  header {
    position: fixed;
    padding: 5px 0;
    z-index: 1300;
  }
}
