.gfn-wrap > * {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 0 0;
  cursor: pointer;
}

.pink-back {
  background: #e50e47 !important;
}

.green-back {
  background: #10c26d !important;
}

.uc-label {
  font-size: 13px;
  line-height: 21px;
  text-align: left;
  color: #000;
  padding: 2px 5px;
  background: #fff;
  border-radius: 3px;
}

.upc-grid .grid-card-img {
  height: 250px;
}

.upc-grid .grid-bottom {
  bottom: 70px;
}

.upc-grid .schedule {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
  border-radius: 0 0 3px 3px;
  padding: 10px;
  font-family: "Avenir-Medium";
  font-weight: normal;
  font-size: 15px;
  text-align: center;
  color: #000;
}

.sc-links {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sc-links a {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: #9faebb !important;
}

.no-views {
  height: 22px;
  border-radius: 3px;
  background: #fff;
  font-family: "Avenir-Heavy";
  font-size: 13px;
  line-height: 16px;
  color: #000;
  float: right;
  padding: 3px 5px;
}

.buyChip {
  background: #000;
  text-align: center;
  color: white;
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 3px 10px;
  border-radius: 100px;
}
