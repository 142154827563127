.schedule-tour {
  border: none !important;
}

.schedule-tour > div {
  width: 100% !important;
  padding: 0 !important;
}

.schedule-tour .stDatesCnt {
  display: flex;
  justify-content: space-between;
}

.schedule-tour .stDatesCnt .stDates {
  font-size: 14px;
  color: #000;
  text-align: center;
  border: 1px solid var(--primColor);
  padding: 5px;
  width: 100px;
  cursor: pointer;
}

.schedule-tour .stDatesCnt .stDates span {
  font-weight: 600;
  font-size: 18px;
  display: block;
}

.schedule-tour .stDays {
  border: 1px solid #b6b6b6;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #000;
  margin-top: 5px;
  cursor: pointer;
}

.schedule-tour .showMore {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 5px auto;
  width: 100%;
  font-size: 14px;
  margin-top: 5px;
}

.schedule-tour .showMore i {
  margin-right: 3px;
  display: inline-block;
}

.schedule-tour .openHouse .stDays {
  height: auto;
  flex-wrap: wrap;
  text-align: center;
  padding: 10px;
}

.schedule-tour .openHouse .stDays h4 {
  width: 100%;
  font-size: 18px;
  line-height: 28px;
  color: #000;
  font-weight: 700;
}

.schedule-tour .openHouse .stDays span {
  width: 100%;
  display: block;
}

.schedule .MuiAppBar-colorPrimary {
  background: #fff;
  box-shadow: none;
}

.schedule .MuiAppBar-colorPrimary .MuiTab-wrapper {
  color: #000;
}

.schedule .MuiTabs-indicator {
  display: none !important;
}

.schedule .MuiTabs-flexContainer {
  width: max-content;
  background: #f2f5f7;
  border-radius: 50px;
  padding: 5px;
  height: 40px;
}

.schedule .MuiTab-root {
  font-size: 14px;
  height: 35px;
  min-height: auto;
  min-width: 120px;
}

.schedule .MuiTab-root.Mui-selected {
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 85px;
  color: var(--primColor);
}

.schedule .MuiTab-root.Mui-selected span {
  color: var(--primColor);
}

.schedule .MuiBox-root {
  padding: 0;
}

.schedule button,
.schedule div {
  outline: 0;
}

.propertyDesc {
  word-break: break-word;
}

.btn-primary:disabled:hover {
  background-color: gray !important;
  border: none !important;
}
.blked {
  background-color: green;
  color: white !important;
}
.slot-disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: default !important;
}
.confrm-tour .primButton {
  max-width: 250px;
  width: 100%;
  margin: 0 auto;
}
.agent-email {
  padding: 0 3rem;
  text-align: left;
}
.agent-email .customInput .MuiFormControl-root .MuiInputBase-root {
  height: 35px;
}
.seller-terms-dtls {
  font-size: 12px;
  text-align: center;
}
.seller-terms-dtls a:hover {
  color: var(--primColor);
}
.seller-terms-dtls a {
  text-decoration: underline;
}
.mob-slider-height {
  height: 173px;
}
@media (max-width: 1199px) {
  .schedule-tour > div {
    width: 120px !important;
    padding: 0 !important;
  }
}

@media (max-width: 767px) {
  .schedule-tour > div {
    width: 33% !important;
  }

  .schedule-tour .stDatesCnt {
    width: 98%;
    margin-bottom: 5px;
  }
}
