.primButton.small .MuiButton-containedPrimary {
  height: 40px !important;
}

.oldAgentActionButtons div,
.newAgentActionButton div {
  display: inline-block;
  margin-left: 10px;
}

.no-decoration button {
  text-transform: none;
  font-size: 13px;
}

.oldAgentActionButtons,
.newAgentActionButton {
  text-align: right;
  height: 50px;
  padding-top: 10px;
}

.newAgentSection {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .oldAgentActionButtons div,
  .newAgentActionButton div {
    margin-left: 5px;
  }
}
