.dashboard-wrapper {
  padding: 65px 15px 30px;
  display: flex;
  max-width: 1095px;
  flex-wrap: wrap;
}

.dashoard-left {
  width: 240px;
  margin-right: 15px;
}

.dashoard-right {
  flex: 1;
  max-width: calc(100% - 255px);
}

.pp-head {
  font-weight: normal;
  font-size: 24px;
  text-align: left;
  color: #000;
}

.pp-head span {
  font-size: 16px;
  color: #9faebb;
  display: inline-block;
  vertical-align: middle;
}

.pc-descp {
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: #595959;
  margin: 12px 0 0;
  font-family: "Avenir-Book";
}

.dashoard-right > .card {
  margin-bottom: 10px;
}

.btp-link a,
.dbc-text {
  font-size: 14px;
  line-height: 21px;
  color: #000;
  font-family: "Avenir-Light";
  font-weight: 800;
}

.dbc-text {
  margin-bottom: 0;
}

.btp-link a .fa {
  color: var(--primColor);
  font-size: 16px;
  margin-right: 5px;
  line-height: 19px;
}

.cwhite-box {
  width: 100%;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.08);
  padding: 12px;
  margin: 15px 0 0;
}

.dbc-text span {
  font-size: 12px;
  color: #000;
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
  margin-left: 15px;
  margin-top: -10px;
  font-weight: 400;
}

.dbc-text div.comment-user-name {
  display: inline-block;
  max-width: calc(100% - 14px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: auto;
}
.name-comment {
  display: grid;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  grid-template-columns: 46% auto;
}
a.reply-link {
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: #000;
  margin-left: 12px;
  font-weight: 600;
  font-family: "Avenir-Medium";
}

.profile-name {
  font-size: 16px;
  color: #000;
  margin: 12px 0 5px;
  font-weight: 600;
  font-family: "Avenir-Medium";
}

.user-img {
  width: 64px;
  height: 64px;
  object-fit: cover;
  object-position: center;
  margin-right: 12px;
}

.db-menu {
  padding: 10px 0;
  list-style: none;
}

.db-menu li a {
  padding: 12px;
  background: transparent;
  font-size: 12px;
  line-height: 12px;
  text-align: left;
  color: #505d68;
  display: block;
  cursor: pointer;
  border-left: 3px solid transparent;
  margin-bottom: 2px;
  font-family: "Avenir-Heavy";
  font-weight: 600;
}

.db-menu li a.active,
.db-menu li a:hover {
  border-left: 3px solid var(--primColor);
  background: #f8fafb;
  text-decoration: none;
}

.glv-link {
  color: #9faebb;
  display: inline-block;
  vertical-align: middle;
  padding: 2px 5px;
  margin-left: 15px;
  font-family: "Avenir-Heavy";
  min-width: 70px;
}

.glv-link .fa {
  margin-right: 5px;
}

.glv-link.active,
.glv-link:hover {
  background: #fff;
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}

.glv-link.active .fa,
.glv-link:hover .fa {
  color: var(--primColor);
}

.grid-row {
  margin: 0 -5px;
}

.grid-row .grid-col {
  padding: 0 5px;
}

.dbw-box {
  margin-top: 0;
}

.dbw-box .db-menu {
  margin: 0 -12px;
}

.dbw-box > h5 {
  font-family: "Avenir-Medium";
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: #9faebb;
  padding-bottom: 5px;
  border-bottom: 1px solid #e7edf0;
  margin: 0;
}

.glv-wrap .form-control {
  display: inline-block;
  color: var(--primColor);
  width: auto;
  background: transparent;
  padding: 0;
  border: none;
  font-size: 14px;
  height: auto;
  vertical-align: middle;
}

.glv-wrap .selectWrapper::after {
  top: 0;
  height: 20px;
  line-height: 1;
}

.btn-default-trans {
  background: #fff;
  border-color: var(--gray);
  color: var(--gray);
  height: 40px;
  min-width: 100px;
  line-height: 18px;
  padding: 10px;
  text-transform: uppercase;
}

.btn-default-trans:hover,
.btn-default-trans:focus {
  text-decoration: none;
  cursor: pointer;
  background: var(--gray);
  border-color: var(--gray);
  color: #fff;
}

.auctions-won-wrapper .glv-link.active,
.auctions-won-wrapper .glv-link:hover {
  background: transparent;
  color: var(--primColor);
}

.dashoard-left .btn-primary {
  text-transform: none;
  font-family: "Avenir-Medium";
}

.pp-head span {
  font-family: "Avenir-Book";
  font-weight: 600;
}

.back-link .fa,
.back-link .fa span {
  display: inline-block;
  vertical-align: middle;
}

form.profile-form-wrapper .form-group {
  margin-top: 0 !important;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .dashoard-left {
    width: 200px;
    margin-top: 1rem;
  }
  .dashoard-right {
    max-width: calc(100% - 215px);
    margin-top: 1rem;
  }
}

@media (max-width: 992px) {
  .glv-wrap.float-right {
    float: none !important;
    clear: both;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .grid-card .nf-list > div {
    display: block;
    vertical-align: middle;
    margin: 0;
    float: none;
  }

  .grid-card .nf-list > div + div {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .home-container {
    margin-top: 60px;
  }
  .glv-link {
    margin: 0 10px 0 0;
  }

  .auctions-won-wrapper .glv-link {
    padding: 0;
  }

  .dashoard-left {
    width: 100%;
    margin: 0 0 15px;
    display: none;
  }

  .rdb-menu.hide {
    display: none;
  }

  .rdb-menu.show {
    display: block;
  }

  .dbc-text span {
    clear: both;
    display: block;
    margin: 8px 0 0;
  }

  .btp-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .btp-link a.menu-btn {
    padding: 6px;
    height: 34px;
    background: var(--primColor);
    border: 1px solid var(--primColor);
    color: #fff;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    min-width: 100px;
  }

  .btp-link a.menu-btn:hover,
  .btp-link a.menu-btn:focus {
    background: #fff;
    border: 1px solid var(--primColor);
    color: var(--primColor);
  }

  .dashoard-right {
    max-width: 100%;
    /* margin-top: 4rem; */
  }

  .dbc-text div.comment-user-name {
    /* max-width: 170px; */
  }
}

.my-bids-boxWrap .edit {
  width: max-content;
  margin-left: auto;
  margin-bottom: 25px;
  cursor: pointer;
}

.my-bids-boxWrap {
  background: #fff;
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 18px;
  padding-bottom: 25px;
}

.my-bids-boxWrap .my-bids-box {
  display: flex;
  width: 100%;
  justify-content: space-around;
  text-align: center;
}

/* .my-bids-box {
  display: flex;
    width: 100%;
    justify-content: space-between;
    background: #fff;
    text-align: center;
    padding: 30px;
    border-radius: 4px;
    margin-bottom: 18px;
} */

.my-bids-box > div {
  width: 33%;
}

.mbb-label {
  color: #9faebb;
  font-size: 14px;
  font-weight: 800;
}

.mbb-data {
  font-size: 24px;
  line-height: 32.78px;
  font-weight: 400;
  margin-bottom: 5px;
}

.aw-card {
  background: #fff;
  margin-bottom: 24px;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.08);
}

.awc-bottom {
  padding: 2px 35px 16px;
}

.awc-bottom p {
  font-size: 14px;
  color: #9faebb;
  line-height: 22px;
  margin: 0;
}

.aw-steps {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-bottom: 15px;
  font-family: "Avenir-Medium";
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  color: #9faebb;
}

.aw-steps > div {
  width: 25%;
  text-align: center;
  position: relative;
  font-family: "Avenir-Book";
  font-weight: 600;
  margin-top: 2px;
}

.step-circle {
  border: 1px solid #e7edf0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin: 0 auto 10px;
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 110;
  background: #fff;
}

.aw-steps .active .step-circle {
  border-color: var(--primColor);
}

.aw-steps .completed .step-circle {
  background: var(--primColor);
  border-color: var(--primColor);
}

.aw-steps > div.active,
.aw-steps .completed {
  color: #000000;
}

.aw-step {
  position: relative;
}

.aw-step:after {
  position: absolute;
  border-bottom: 1px solid #e7edf0;
  width: 100%;
  height: 1px;
  top: 9px;
  left: 50%;
}

.aw-step.completed:after {
  border-bottom: 1px solid var(--primColor);
}

.card-body.media.aw-card {
  padding: 0;
}

.dashoard-right > .card.comments-card {
  margin-bottom: 24px;
  border: none;
  border-bottom: 5px solid #e0e0e0;
  border-radius: 0 0 4px 4px;
}

.db-menu li.buyer-verification-link a {
  display: flex;
  justify-content: space-between;
}

.db-menu li.buyer-verification-link a span {
  background: var(--primColor);
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .aw-card .media-left {
    width: 200px;
  }
}

@media (max-width: 767px) {
  .mbb-data {
    font-size: 18px;
    line-height: 24.59px;
  }

  .mbb-label {
    font-size: 12px;
    line-height: 21px;
  }

  .my-bids-boxWrap .edit {
    margin-bottom: 5px;
  }

  .card-body.media.aw-card {
    flex-wrap: wrap;
  }

  .aw-card .media-left {
    width: 210px;
    height: 200px;
  }

  .aw-card-img {
    width: 100%;
    height: 100%;
  }

  .navbar-collapse .cwhite-box {
    box-shadow: none;
    padding: 0;
  }

  .navbar-collapse .dbw-box .db-menu {
    margin: 0;
  }

  .dashboard-wrapper {
    padding: 20px 15px;
  }
}

@media (max-width: 576px) {
  .aw-card .media-left {
    width: 100%;
  }
}
