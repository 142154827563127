.rtngContainer .rtngWrapper .pdtCard {
  min-width: 290px;
}
.rtngContainer .rtngWrapper .rtngWrpr {
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px #00000007;
  width: 100%;
}

.rtngContainer .rtngWrapper .rtngWrpr .rvwWrpr legend {
  font-size: 17px;
  font-weight: 600;
}

.rtngContainer
  .rtngWrapper
  .rtngWrpr
  .rvwWrpr
  .customTextArea
  .MuiTextField-root {
  width: 100%;
}

.rtngContainer .rtngWrapper .rtngWrpr .rvwWrpr .primButton button {
  width: 150px;
  margin-top: 15px;
}
