:root {
  --primColor: #6320ee;
  --secColor: #00e1d2;
  --gray: #42485a;
}

@font-face {
  font-family: "Avenir-Black";
  src: url("./fonts/Avenir-Black.eot");
  src:
    url("./fonts/Avenir-Black.woff2") format("woff2"),
    url("./fonts/Avenir-Black.woff") format("woff"),
    url("./fonts/Avenir-Black.ttf") format("truetype"),
    url("./fonts/Avenir-Black.svg#Avenir-Black") format("svg"),
    url("./fonts/Avenir-Black.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir-Book";
  src: url("./fonts/Avenir-Book.eot");
  src:
    url("./fonts/Avenir-Book.woff2") format("woff2"),
    url("./fonts/Avenir-Book.woff") format("woff"),
    url("./fonts/Avenir-Book.ttf") format("truetype"),
    url("./fonts/Avenir-Book.svg#Avenir-Book") format("svg"),
    url("./fonts/Avenir-Book.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir-Heavy";
  src: url("./fonts/Avenir-Heavy.eot");
  src:
    url("./fonts/Avenir-Heavy.woff2") format("woff2"),
    url("./fonts/Avenir-Heavy.woff") format("woff"),
    url("./fonts/Avenir-Heavy.ttf") format("truetype"),
    url("./fonts/Avenir-Heavy.svg#Avenir-Heavy") format("svg"),
    url("./fonts/Avenir-Heavy.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir-Light";
  src: url("./fonts/Avenir-Light.eot");
  src:
    url("./fonts/Avenir-Light.woff2") format("woff2"),
    url("./fonts/Avenir-Light.woff") format("woff"),
    url("./fonts/Avenir-Light.ttf") format("truetype"),
    url("./fonts/Avenir-Light.svg#Avenir-Light") format("svg"),
    url("./fonts/Avenir-Light.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir-Medium";
  src: url("./fonts/Avenir-Medium.eot");
  src:
    url("./fonts/Avenir-Medium.woff2") format("woff2"),
    url("./fonts/Avenir-Medium.woff") format("woff"),
    url("./fonts/Avenir-Medium.ttf") format("truetype"),
    url("./fonts/Avenir-Medium.svg#Avenir-Medium") format("svg"),
    url("./fonts/Avenir-Medium.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Avenir-Book";
  font-size: 14px;
}
strong {
  font-family: "Avenir-Heavy";
}

.container {
  max-width: 1200px;
}

.main-container,
.home-container {
  min-height: calc(100vh - 285px);
  background: #f2f5f7;
}

.main-container.inCplWrpr {
  padding-top: 100px;
}

.main-container {
  padding: 90px 0 60px 0;
}

.usrRegCheck {
  padding: 10px;
  text-align: center;
  background: #ffd9d9;
  position: absolute;
  top: 63px;
  width: 100%;
}

.customContainer {
  padding-left: 60px;
  padding-right: 60px;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.usrRegCheck p {
  margin: 0;
}

.usrRegCheck p a {
  cursor: pointer;
  color: var(--primColor) !important;
}

/* .main-container.inCplWrpr .btmStckyHdr {
  top: 104px;
} */

.invalid-feedback {
  color: red;
  font-size: 12px;
  margin: 5px 0 0;
  display: block;
  font-weight: 400;
}

a {
  color: var(--primColor);
}

.btn-primary {
  background: var(--primColor) !important;
  border-color: var(--primColor) !important;
  color: #fff;
  height: 40px;
  min-width: 100px;
  line-height: 18px;
  padding: 10px;
  text-transform: uppercase;
}

.btn-invert,
.btn-invert:focus {
  background: #fff !important;
  border-color: var(--primColor) !important;
  color: var(--primColor);
  height: 32px;
  min-width: 100px;
  line-height: 12px;
  padding: 10px;
}

.btn-primary:hover,
.btn-primary:focus {
  background: #fff !important;
  border-color: var(--primColor) !important;
  color: var(--primColor);
}

.btn-invert:hover {
  background: var(--primColor) !important;
  border-color: var(--primColor) !important;
  color: #fff;
}

.btn-default {
  background: var(--gray);
  border-color: var(--gray);
  color: #fff;
  height: 40px;
  min-width: 100px;
  line-height: 18px;
  padding: 10px;
  text-transform: uppercase;
}

.btn-default:hover,
.btn-default:focus {
  background: #fff;
  border-color: var(--gray);
  color: var(--gray);
}

.btn + .btn {
  margin-left: 10px;
}

.accordion {
  margin-bottom: 10px;
}

.accordion-toggle {
  border-bottom: 1px solid #e7edf0;
  display: block;
  padding: 10px 0;
  position: relative;
  height: 48px;
  font-size: 18px;
  text-align: left;
  color: #000;
}

.accordion-toggle .icon-link {
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 60px;
  height: 20px;
  padding: 2px 3px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: #000;
  font-family: "Avenir-Heavy";
}

.accordion-toggle .icon-link .fa {
  color: var(--primColor);
}

.accordion-toggle .icon-link > * {
  display: inline-block;
  vertical-align: middle;
}

.accordion-toggle:hover,
.accordion-toggle:focus {
  color: var(--primColor);
  background: #fff;
  text-decoration: none;
  cursor: pointer;
}

.accordion-content {
  padding: 10px;
  border: 1px solid #e7edf0;
  background-color: #fff;
  margin-top: -1px;
}

.hi-wrapper {
  padding: 200px 0 75px;
  background: #fff;
}

.hm-title {
  position: relative;
  font-size: 48px;
  line-height: 65.57px;
  text-align: center;
  color: #000;
  margin-bottom: 90px;
  font-family: "Avenir-Light";
  font-weight: bold;
  margin-top: 37px;
}

.hm-title:after {
  width: 100px;
  height: 3px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -10px;
  background: var(--primColor);
}

.hi-title {
  font-size: 21px;
  line-height: 31px;
  text-align: center;
  color: #000;
  font-family: "Avenir-Medium";
  font-weight: 500;
}

.hi-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #a1aeb7;
  font-family: "Ubuntu";
}

.pos-relative {
  position: relative;
}

.buy-sell-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.bs-box {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
}

.bsb-img {
  width: 100%;
  height: 100%;
}

.buy-sell-wrapper .card {
  padding: 20px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 30px;
  width: calc(100% - 30px);
  max-width: 386px;
}

.buy-sell-wrapper .card .btn {
  height: 40px;
  width: 146px;
  margin: 0 auto;
  font-size: 12px;
}

.hi-wrapper.review-wrapper {
  padding: 75px 0;
  background: #f2f5f7;
}

.review-wrapper .slick-prev:before,
.review-wrapper .slick-next:before {
  display: none;
}

.review-wrapper .slick-next,
.review-wrapper .slick-prev {
  font-size: 20px;
  color: #c2d1d9 !important;
  padding: 10px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  z-index: 9999;
  cursor: pointer;
  top: inherit;
  bottom: -54px;
}

.review-wrapper .slick-next {
  right: 0;
}

.review-wrapper .slick-prev {
  left: 0;
}

.review-wrapper .slick-slide .item {
  width: 100%;
  display: inline-block;
  max-width: 360px;
  margin: 0 auto;
  background: #fff;
  padding: 20px 10px;
}

select {
  -webkit-appearance: none;
  appearance: none;
  border: #c8cfd3;
  color: #b0b7bb !important;
}

select:focus {
  color: black !important;
}

.selectWrapper {
  position: relative;
  width: auto;
  padding-right: 30px;
}

.syh-form-wrapper .selectWrapper {
  padding-right: 0px;
}

.bid-amount .selectWrapper,
.nav-item .selectWrapper,
.pl-form-wrapper .selectWrapper,
.home-top-wrapper .selectWrapper,
.search-right .selectWrapper {
  padding-right: 0;
}

.nav-item .selectWrapper .form-control,
.pl-form-wrapper .selectWrapper .form-control,
.home-top-wrapper .selectWrapper .form-control,
.search-right .selectWrapper .form-control {
  padding-right: 30px;
}

.nav-item .selectWrapper::after,
.pl-form-wrapper .selectWrapper::after,
.home-top-wrapper .selectWrapper::after,
.search-right .selectWrapper::after {
  top: 1px;
}

.selectWrapper::after {
  font-family: "Material Icons";
  content: "arrow_drop_down";
  font-size: 25px;
  top: 15%;
  right: 5px;
  color: #b0b7bb;
  position: absolute;
}

.bid-amount .selectWrapper::after {
  font-family: "Material Icons";
  content: "arrow_drop_down";
  font-size: 25px;
  top: 0%;
  right: 5px;
  color: #b0b7bb;
  position: absolute;
}

.post-listing-wrapper .selectWrapper select,
.post-listing-wrapper input {
  height: 45px;
}

.post-listing-wrapper .selectWrapper::after {
  top: 10%;
}

.btn.borderViolet {
  border: 1px solid var(--primColor) !important;
  color: var(--primColor) !important;
}

.btn.borderViolet:hover {
  background-color: var(--primColor) !important;
  border: var(--primColor) !important;
  color: white !important;
}

.shc-wrap .btn.btn-default {
  font-family: "Avenir-Medium";
  font-size: 12px;
  text-transform: uppercase;
}

.fa.fa-eye {
  font-family: FontAwesome;
}

.auth-bottom {
  font-family: "Avenir-Medium";
}

.auth-bottom a {
  font-family: "Avenir-Book";
  font-weight: 600;
}

.chkContainer .MuiTypography-root.MuiFormControlLabel-label {
  font-size: 14.5px;
  color: #595959;
  font-family: "Avenir-Medium";
}

form .form-group {
  margin-top: 1.6rem !important;
}

.MuiOutlinedInput-root input::placeholder {
  font-size: 14px !important;
  color: #c2d1d9;
  font-family: "Avenir-Medium";
}

.mapInput .MuiOutlinedInput-notchedOutline {
  /* top: unset !important; */
  border: 1px solid #ced4da;
}

.mapInput .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid #ced4da;
}

.mapInput .MuiButtonBase-root.MuiAutocomplete-popupIndicator {
  display: none;
}

.mapInput .MuiInputBase-root.MuiOutlinedInput-root.MuiOutlinedInput-adornedEnd {
  padding: 0;
}

.mapInput
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-right: 0;
}

.text-green {
  color: #058f33;
}

.text-red {
  color: #ff3b3b;
}

.mt-34 {
  margin-top: 34px;
}

.table thead {
  background: #dde4f4;
  background: #dde4f4;
  font-size: 16px;
  text-align: left;
  color: #333;
}

.table td {
}

.table td a {
  font-size: 16px;
  color: #233e7c;
}

.custom-modal {
  padding: 30px;
}

.custom-modal .modal-header {
  background: var(--primColor);
}
.custom-modal .modal-title {
  text-align: center;
  width: calc(100% - 55px);
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
}

.show.custom-modal {
  display: block;
}

.modal-header .close {
  color: #fff;
  opacity: 1;
}

.notifications .MuiCheckbox-root {
  padding: 0;
  margin-left: 11px;
}

.MuiDropzoneArea-text {
  color: #707070 !important;
  font-size: 16px !important;
}

.MuiDropzoneArea-icon {
  color: #707070 !important;
  width: 35px !important;
  height: 35px !important;
}

.MuiDropzonePreviewList-image {
  width: 125px !important;
  height: 100px !important;
}

.MuiGrid-root.MuiDropzonePreviewList-root.MuiGrid-container {
  width: 100% !important;
  margin: 0 !important;
  margin-top: 20px !important;
}

.MuiGrid-root.MuiDropzonePreviewList-root.MuiGrid-container > .MuiGrid-item {
  padding: 0 !important;
}

.custom-modal .modal-dialog {
  max-width: 740px;
}

.custom-modal .modal-content {
  max-height: 90vh;
  overflow: auto;
}

button:disabled {
  background-color: #707070 !important;
  border: 1px solid #707070 !important;
  color: white !important;
}

.sc-gbox .MuiButtonBase-root.MuiCheckbox-root {
  padding: 0 !important;
}

.max-540 {
  max-width: 540px;
  width: 90%;
  margin: 30px auto;
}

.rm-close {
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
  z-index: 99;
}

.error404 {
  padding-top: 90px;
  padding-bottom: 60px;
}

.bidHistoryCnt {
  margin: 5px 0 30px;
}

.bidHistoryCnt .MuiButton-root {
  margin-right: 15px;
  color: var(--primColor);
  border-color: var(--primColor);
}

.bidHistoryCnt .MuiButton-root:hover {
  color: #fff;
  background: var(--primColor);
}

.bidHistoryCnt .MuiButton-root .MuiButton-label {
  text-transform: initial;
  font-weight: 400;
  font-family: "Avenir-medium";
}

.bidHistoryCnt .MuiButton-root .material-icons,
.bidHistoryCnt .MuiButton-root .material-icons-outlined {
  margin-right: 5px;
}

.bidHistoryModal table {
  margin-top: 20px;
}

.bidHistoryModal table thead {
  background: #fff;
}

.bidHistoryModal table thead th {
  padding-top: 5px;
  padding-bottom: 5px;
}

.bid-box.highBidder {
  cursor: pointer;
  background: #fff;
  color: var(--primColor);
  border: 1px solid var(--primColor);
}

.bid-box.highBidder .text-right {
  color: var(--primColor);
}

@media (max-width: 1200px) {
  .review-wrapper .slick-next {
    right: 0;
  }

  .cvv {
    padding-right: 10px;
    padding-left: 10px;
  }

  .review-wrapper .slick-prev {
    left: 0;
  }

  .review-wrapper .slick-dots {
    bottom: 0;
  }

  html,
  body {
    max-width: 100vw;
    overflow-x: hidden;
  }
}

.db-mt-35 {
  margin-top: -35px;
}

.favoriteCheck .fa-heart {
  display: inline-block;
  vertical-align: middle;
}

.pvt-content-wrap .list-inline-item:not(:last-child) {
  margin: 0 5px 0 15px;
}

.pvt-content-wrap li .fa-heart {
  padding-right: 5px;
}

.pvt-content-wrap li .fa-angle-down {
  padding-left: 5px;
}

.pay-calc-head {
  text-align: left;
  font-weight: 400;
  font-size: 24px;
  line-height: 32.78px;
  font-family: "Avenir-Light";
}

.sls-head {
  font-size: 24px;
  margin: 64px auto 34px;
  color: #000;
  font-family: "Avenir-Light";
  font-weight: 400;
  line-height: 32.78px;
  text-align: center;
}

.sls-wrapper .aw-steps {
  max-width: 562px;
  margin: auto;
}

.thank-you-box {
  width: 100%;
  max-width: 562px;
  height: 274px;
  background: #fff;
  text-align: center;
  margin: 25px auto;
  padding: 30px;
}

.ty-check {
  width: 64px;
  height: 64px;
  margin: 0 auto;
  border: 1px solid var(--primColor);
  border-radius: 50%;
  font-size: 36px;
  color: var(--primColor);
  padding: 6px;
}

.ty-head {
  font-size: 24px;
  line-height: 32.78px;
  text-align: center;
  margin: 34px auto 12px;
}

.ty-note {
  font-size: 14px;
  line-height: 21px;
  max-width: 325px;
  margin: 0 auto 20px;
  color: #595959;
}

.go-to-link {
  font-size: 14px;
  line-height: 21px;
  color: #000;
  font-weight: 800;
  font-family: "Avenir-Medium";
}

.go-to-link > .fa {
  display: inline-block;
  vertical-align: middle;
  color: var(--primColor);
  font-size: 16px;
  line-height: 24px;
  margin-left: 5px;
}

.slc-wrapper .syh-cp-box {
  height: 251px;
}

.slc-btm {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.slc-btm > .btn-border,
.slc-btm > .scp-btn-wrap {
  width: 48%;
  margin: 14px auto 0;
  height: 44px;
}

.slc-btm > .btn-border {
  line-height: 24px;
}

.slc-wrapper .scp-btn-wrap input[type="radio"] ~ label {
  color: var(--primColor);
  border: 1px solid var(--primColor);
  background: #fff;
}

.slc-wrapper .scp-btn-wrap input[type="radio"]:checked ~ label {
  color: #fff;
  border: 1px solid var(--primColor);
  background: var(--primColor);
}

.checkout-bottom {
  max-width: 960px;
  margin: 0 auto;
}

.slc-white-box {
  height: 100%;
  background: #fff;
  padding: 18px;
}

form .slc-white-box .form-group {
  margin: 0 0 14px !important;
}

.checkout-bottom form > div.row {
  margin: 0 -8px;
}

.checkout-bottom form > div.row > div {
  padding: 0 8px;
}

.slc-bhead {
  font-size: 18px;
  line-height: 24.59px;
  color: #000;
  margin-bottom: 18px;
}

.tc-text {
  padding: 14px 0 8px;
  border-bottom: 1px solid #e7edf0;
}

.tc-text:last-child {
  font-weight: 500;
  font-family: "Avenir-Medium";
  border: none;
}

.commentsCardDate {
  font-family: "Avenir-Book";
  font-weight: 800;
  font-size: 12px;
  line-height: 19px;
  text-align: right;
  color: #9faebb;
}

@media (min-width: 768px) {
  .checkout-bottom form > div.row > div.col-md-3 {
    -ms-flex: 0 0 260px;
    flex: 0 0 260px;
    max-width: 260px;
  }

  .checkout-bottom form > div.row > div.col-md-6 {
    -ms-flex: 0 0 455px;
    flex: 0 0 455px;
    max-width: 455px;
  }
}

a.sc-learn-more {
  margin-top: 20px;
  display: inline-block;
  text-decoration: none;
  font-weight: 800;
}

.syh-checkout-btn .MuiButton-containedPrimary {
  display: inline-block;
  max-width: 160px;
  height: 40px;
  margin-top: 24px;
}

.slc-back {
  max-width: 960px;
  margin: 0 auto;
}

.slc-back > span {
  display: inline-block;
  vertical-align: middle;
  line-height: 33px;
  font-size: 24px;
  margin-left: 14px;
  display: inline-block;
  vertical-align: middle;
  line-height: 33px;
  font-size: 24px;
  margin-left: 14px;
}

.slc-back > a {
  display: inline-block;
  vertical-align: middle;
}

.review-wrapper .hm-title {
  margin: 0 0 45px;
  font-size: 52px;
  font-weight: normal;
}

.MuiInputBase-root input::placeholder {
  color: #454545;
}

.usrEml {
  border: 1px solid #ffffff;
  border-radius: 3px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: whitesmoke;
}

.usrEml span {
  font-weight: 600;
  font-size: 15px;
  font-family: "Avenir-Book";
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 768px) and (max-width: 991px) {
  .review-wrapper .slick-slide .item {
    max-width: calc(100% - 10px);
  }
}

@media (max-width: 767px) {
  .main-container {
    padding: 60px 0 10px;
  }
  .bs-box {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    height: 240px;
  }

  .buy-sell-wrapper .card {
    bottom: 20px;
  }

  .slick-slide .mb-5 {
    margin-bottom: 0 !important;
  }

  .hm-title,
  .review-wrapper .hm-title {
    font-size: 22px;
    line-height: 1.67;
    margin: 0px auto 10px;
  }

  .bs-wrap .mb-5 {
    margin: 1.5rem 0 0 !important;
  }

  .mt-34 {
    margin-top: 0px;
  }

  .table td,
  .table th {
    padding: 5px;
    font-size: 12px;
  }

  .table-responsive .table {
    min-width: 767px;
  }

  .accordion-toggle {
    height: auto;
    font-size: 14px;
    min-height: 34px;
  }

  .accordion-toggle span {
    max-width: calc(100% - 65px);
    display: inline-block;
  }
  .grid-card .nf-list {
    margin: 0 !important;
  }

  .accordion-toggle .icon-link {
    padding: 0 3px;
    height: 24px;
  }

  .nav-item .selectWrapper .form-control,
  .pl-form-wrapper .selectWrapper .form-control,
  .home-top-wrapper .selectWrapper .form-control,
  .search-right .selectWrapper .form-control {
    padding: 5px 20px 5px 5px;
    font-size: 13px;
    min-width: 100%;
    height: 35px;
  }

  .nav-item .selectWrapper::after,
  .pl-form-wrapper .selectWrapper::after,
  .home-top-wrapper .selectWrapper::after,
  .search-right .selectWrapper::after {
    font-size: 22px;
  }

  .btn-primary {
    background: var(--primColor) !important;
    border-color: var(--primColor) !important;
    color: #fff;
    height: 35px;
    min-width: 136px;
    line-height: 16.39px;
    padding: 10px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .db-mt-35 {
    margin-top: 0;
  }

  .my-bids-boxWrap {
    background: #fff;
    padding: 12px 1px;
    border-radius: 4px;
    margin-bottom: 18px;
  }

  .my-bids-boxWrap .my-bids-box {
    display: flex;
    width: 100%;
    justify-content: space-around;
    text-align: center;
  }

  .mbb-data {
    font-size: 18px;
    line-height: 24.59px;
  }
  .mbb-label {
    font-size: 12px;
    line-height: 21px;
  }

  .my-bids-box > div {
    width: auto;
    max-width: 33%;
  }

  .pp-head {
    font-size: 18px;
    line-height: 24.59px;
  }

  .pp-head span {
    font-size: 14px;
    line-height: 22px;
  }

  .card-body .grid-bottom .itmCty,
  .card-body .grid-bottom .itmSpecs,
  .card-body .grid-bottom .itmAdrs {
    font-size: 11px;
  }

  .main-container.inCplWrpr {
    padding-top: 120px;
  }

  .usrRegCheck {
    top: 68px;
  }

  .srchPdg.usrRegCheck {
    top: 113px;
    z-index: 1;
  }
}

.slick-slider-custom {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 30px;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.property-details a.btn-primary {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-context {
  display: flex;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 9999;
  font-size: 120px;
  align-items: center;
  justify-content: center;
  color: var(--primColor);
}
