.user-verfication-step {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border-radius: 8px;
}

.user-verfication-step .step-number {
  width: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px 0 0 8px;
  margin: 0px;
  color: #fff;
  font-size: 25px;
  background: var(--primColor);
}

.user-verfication-step .step-components .step-header {
  display: flex;
  justify-content: space-between;
}

.user-verfication-step .step-components .step-header .step-incomplete-status {
  border: 1px solid red;
  border-radius: 8px;
  color: red;
  padding: 2px 15px;
}

.user-verfication-step .step-components .auth-title {
  text-align: left;
}

.user-verfication-step .step-components {
  width: 100%;
  padding: 15px;
}

@media (max-width: 767px) {
  .user-verfication-step .step-number {
    min-width: 20px;
    width: 20px;
  }

  .user-verfication-step .step-components .step-header .step-incomplete-status {
    padding: 2px 5px;
  }

  .user-verfication-step .step-components .step-header {
    padding-right: 15px;
  }
}
