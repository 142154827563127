.cstmDialogWrapper .MuiPaper-root.MuiDialog-paper {
  max-width: 800px;
  width: 100%;
  padding: 20px;
  position: relative;
}

.cstmDialogWrapper .MuiPaper-root.MuiDialog-paper .clsButton {
  position: absolute;
  top: 6px;
  right: 6px;
}

.cstmDialogWrapper .MuiPaper-root.MuiDialog-paper .clsButtonText {
  position: absolute;
  top: 15px;
  right: 15px;
  color: var(--primColor);
  cursor: pointer;
}

.cstmDialogWrapper.beforePopup .MuiPaper-root.MuiDialog-paper {
  max-width: 430px;
}

.cstmDialogWrapper .modalTitle h4 {
  font-size: 18px;
  font-family: "Avenir-Heavy";
}
