.cartWrapper .cartInnerWrapper {
  display: grid;
  grid-template-columns: 1fr 360px;
  gap: 20px;
}

.cartWrapper .cartCardWrapper {
  width: 100%;
}

.cartCard {
  background: white;
  display: flex;
  align-items: flex-start;
}

.cartCard .cartImgWrpr {
  width: 210px;
  height: 130px;
}

.cartCard .cartImgWrpr img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.cartCard .cartPdtInfo {
  padding: 10px;
}

.cartCard .cartPdtInfo .cartPdtPrice {
  color: var(--primColor);
  font-size: 20px;
  font-family: "Avenir-Medium";
  margin-bottom: 6px;
}

.cartWrapper .cartPriceWrapper {
  width: 100%;
  max-width: 360px;
}

.cartWrapper .cartPriceWrapper .cartPriceCard {
  background: white;
  border-radius: 5px;
  padding: 15px;
}

.cartWrapper .cartPriceWrapper .cartPriceCard h3 {
  font-size: 18px;
}

.cartWrapper .cartPriceWrapper ul.paymentInfoDetails {
  list-style: none;
  padding-left: 0;
  margin-top: 23px;
}

.cartWrapper .cartPriceWrapper .paymentInfoDetails li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.cartWrapper .cartPriceWrapper .paymentInfoDetails li label {
  margin-bottom: 0;
}

.cartWrapper .cartPriceWrapper .paymentInfoDetails li span {
  font-family: "Avenir-Medium";
  color: var(--primColor);
}

.cartWrapper .cartPriceWrapper .totalFeeAmt {
  font-size: 20px;
  font-family: "Avenir-Heavy";
}

.confimBuyPopup {
  text-align: center;
}

.confimBuyPopup .spIcn .material-icons {
  font-size: 57px;
  color: var(--primColor);
}

.confimBuyPopup h2 {
  margin: 15px 0;
  font-size: 26px;
  font-family: "Avenir-Medium";
}

.confimBuyPopup p {
  font-size: 17px;
}

.confimBuyPopup .primButton {
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}
