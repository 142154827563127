.sc-gbox.bidding-form-wrapper {
  background: #fff;
  max-width: 650px;
  margin: 0 auto;
}

.bidding-form-wrapper p {
  font-family: "Avenir-Medium";
  text-align: center;
  font-size: 16px;
  color: #000;
  line-height: 21px;
  margin: 0;
}

.bidding-box {
  padding: 30px 30px;
  margin: 25px auto;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}

.mobile-bidding-box {
  padding: 30px 10px;
  margin: 25px auto;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}

.bid-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .MuiPopover-root .MuiMenu-paper ul li {
  border-top: solid 2px gray;
}

.MuiPopover-root .MuiMenu-paper ul li:first-child {
  border-top: none;
} */

.bid-amount .bid-input-wrap {
  position: relative;
}

.mobile-bidding-box .bid-amount .bid-input-wrap {
  width: 60%;
}

/* .mobile-bidding-box .bform-label {
  margin-bottom: 15px; */
/* } */

.bid-amount .bid-input-wrap span {
  position: absolute;
  top: 8px;
  left: 8px;
}

.bid-amount .bid-input-wrap .form-control {
  padding: 6px 12px 6px 25px;
  text-align: right;
}

.bform-label {
  font-family: "Avenir-Medium";
  font-size: 16px;
  color: #000;
  line-height: 21px;
}

.mobile-bidding-box .bform-label {
  font-size: 14px;
  line-height: 19px;
}

p.mobile-seller-terms {
  font-size: 14px;
}

.bfc-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.bidding-form-wrapper .MuiFormControlLabel-root {
  margin: 0;
}

.bidding-form-wrapper .table td {
  padding: 0.25rem 0.75rem;
}

.bform-pb-btn {
  max-width: 240px;
  width: 100%;
  margin: 0 auto;
  display: block;
  height: 46px;
}

.bid-notify-alert p {
  text-align: left;
  color: #fb0000;
  font-size: 12px;
}
.non-refundable-txt {
  color: #595959;
}
.non-refund-amt .refund-icon {
  color: #c4c4c4;
}
.bidding-check-box
  .MuiFormGroup-root
  .MuiFormControlLabel-root
  .MuiTypography-root,
.bidding-check-box span {
  font-size: 14px;
}
.mobile-popup-checkbox
  .MuiFormGroup-root
  .MuiFormControlLabel-root
  .MuiTypography-root,
.mobile-popup-checkbox span {
  font-size: 11px;
}
.mobile-popup-checkbox span div.MuiListItem-gutters {
  padding: 10px 5px;
}

.mobile-popup-checkbox hr,
.mobile-bidding-box hr {
  margin-top: 0px;
  margin-bottom: 15px;
}

.learn-more {
  color: #6320ee !important;
  display: inline;
  margin: 0;
}
.submitBidFormHeader {
  padding: 0 2px;
}
