.ppic-wrap {
  position: relative;
  margin: 35px auto 10px;
  width: 124px;
}

.pp-img {
  width: 124px;
  height: 124px;
  object-fit: cover;
  object-position: center;
}

.pi-head {
  font-weight: 500;
  font-size: 26px;
  text-align: left;
  color: var(--primColor);
}

.pimg-edit,
.img-edit {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 8px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  color: var(--primColor);
  right: 10px;
  bottom: 0;
  margin: 0;
}

.img-edit {
  cursor: pointer;
  z-index: 99;
  opacity: 0;
}

.pp-btn-wrap {
  width: 250px;
  margin: 20px auto;
}

.pp-btn-wrap button,
.pp-btn-wrap .btn-primary-trans,
.pp-btn-wrap .primButton {
  width: 105px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
}

.pp-btn-wrap > .btn-primary-trans {
  margin-left: 40px;
}

.pcd-media {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.07);
  padding: 23px 25px;
  line-height: 21px;
  color: #595959;
  align-items: center;
  position: relative;
}

.pcd-media .media-body {
  padding-left: 25px;
}

.pcd-media .profile-media-img {
  width: 175px;
  height: 175px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.pcd-name {
  font-size: 24px;
  font-weight: 500;
  line-height: 32.78px;
  color: #000;
}

.cnstrtFlg {
  font-size: 13px;
}

.pcd-name span {
  font-size: 14px;
  line-height: 22px;
  display: inline-block;
  vertical-align: middle;
}

.joined-date {
  color: #9faebb;
  font-size: 14px;
  line-height: 20px;
}

.profile-ep-text,
.am-text {
  font-size: 17px;
  font-weight: 400;
  color: #595959;
  line-height: 22px;
}

.padr-txt {
  color: var(--primColor);
}

.about-box {
  border-radius: 4px;
  background: #fff;
  line-height: 21px;
  margin-top: 22px;
  padding: 23px 25px;
}

.pc-margin-bottom {
  margin-bottom: 5px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.am-head {
  font-size: 20px;
  font-weight: 800;
  color: #000;
  margin-bottom: 18px;
}

.pcd-media .edit {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #9faebb;
  cursor: pointer;
}

.about-box textarea {
  width: 100%;
  height: 107px;
  resize: none;
  background: #f2f5f7;
  border-radius: 4px;
  border: 1px solid #f2f5f7;
  padding: 10px;
}

.upload-photo {
  position: relative;
  width: 175px;
  height: 175px;
  background: #f2f5f7;
  border-radius: 50%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  line-height: 24.58px;
  color: #b6b6b6;
}

.upload-photo input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 50%;
  cursor: pointer;
}

.fp-btn .btn {
  height: 40px;
  font-size: 12px;
  width: 146px;
}

.about-input {
  width: 100%;
  height: 35px;
  resize: none;
  background: #f2f5f7;
  border-radius: 4px;
  border: 1px solid #f2f5f7;
  padding: 5px;
  max-width: 300px;
  font-size: 14px;
}

textarea.about-input {
  height: 42px;
}

.about-input:focus,
.about-box textarea:focus {
  border: 1px solid var(--primColor);
  outline: none;
}

/*profileNewCss*/

.pfrlCardNew .pcd-media.prflNwCs {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.07);
  padding: 8px 19px 8px 25px;
  line-height: 21px;
  min-height: 226px;
  color: #595959;
  align-items: center;
  position: relative;
}

.pcd-media.prflNwCs .media-body {
  padding-left: 25px;
}

.pcd-media.prflNwCs .profile-media-img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
}

.pcd-media.prflNwCs .pcd-name {
  font-size: 24px;
  font-weight: 600;
  line-height: 32.78px;
  color: #000;
  display: flex;
  align-items: flex-end;
}

.pcd-media.prflNwCs .pcd-name span {
  font-size: 14px;
  line-height: 22px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
  margin-bottom: 2px;
  font-weight: 600;
}

.pcd-media.prflNwCs .edit {
  position: relative;
  text-align: right;
}

.pcd-media.prflNwCs .joined-date {
  color: #9faebb;
  font-size: 14px;
  line-height: 20px;
}

.pcd-media.prflNwCs .profile-ep-text,
.pcd-media.prflNwCs .am-text {
  font-size: 18px;
  font-weight: 400;
}

.pcd-media.prflNwCs .padr-txt {
  color: var(--primColor);
}

.pcd-media.prflNwCs .about-box {
  border-radius: 4px;
  background: #fff;
  line-height: 21px;
  margin-top: 22px;
  padding: 26px 19px 26px 25px;
}

.pcd-media.prflNwCs .pc-margin-bottom {
  margin: 5px 0;
}

.pcd-media.prflNwCs .am-head {
  font-size: 20px;
  font-weight: 800;
  color: #000;
  margin-bottom: 18px;
}

.pcd-media.prflNwCs .pcd-media .edit {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #9faebb;
  cursor: pointer;
}

.btn-border.contact-btn {
  text-transform: capitalize;
  width: 145px;
  height: 40px;
  font-size: 12px;
  font-weight: 800;
}

.va-container {
  max-width: 1024px;
}

.va-container .profile-card-wrapper {
  max-width: 861px;
  margin: 50px auto 0;
}

.va-container .MuiTab-root {
  min-width: 33%;
  outline: none;
}

.va-container .MuiTab-textColorInherit.Mui-selected {
  background: #f2f2f2;
}

.va-container .MuiTabs-indicator {
  background: var(--primColor);
}

.va-container .MuiTab-wrapper {
  font-size: 12px;
  text-transform: capitalize;
}

.vr-link {
  font-size: 14px;
  line-height: 19.12px;
  color: #000 !important;
  cursor: pointer;
  text-decoration: underline !important;
  margin-top: 12px;
  display: inline-block;
}

.ed-links a {
  display: inline-block;
  vertical-align: middle;
  font-size: 10px;
  line-height: 21px;
  font-weight: 400;
  margin-top: 12px;
  margin-left: 12px;
}

.ed-links img {
  display: inline-block;
  vertical-align: middle;
  max-width: 15px;
  margin-right: 5px;
  max-height: 15px;
}

.upload-photo {
  z-index: 2;
}

.upload-photo .prflImg {
  width: 175px;
  height: 175px;
  border-radius: 100%;
  position: absolute;
  z-index: -1;
}

.prflPhnInpt .MuiOutlinedInput-root input {
  height: 35px !important;
  background-color: #f2f5f7;
}

.client-card {
  padding: 15px;
}

.client-card .client-avatar .profile-media-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.client-card .client-info,
.client-card .action-button {
  margin-top: 25px;
}

.client-card .myclients-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-left: 0px;
}

.client-card button.btn-border {
  line-height: 12px;
  height: 30px;
  padding: 5px;
  font-size: 12px;
  width: auto;
}

.client-card .buttonLeft button.revoke-button,
.client-card .buttonRight button.btn-border {
  border-color: black;
  color: black;
}

.client-card .buttonLeft button.revoke-button:hover,
.client-card .buttonLeft button.revoke-button:focus,
.client-card .buttonRight button.btn-border:hover,
.client-card .buttonRight button.btn-border:focus {
  border-color: black;
  background: black;
  color: #fff;
}

.client-card .buttonLeft,
.client-card .buttonRight,
.client-card .completeCheckbox {
  display: inline-block;
}

.client-card .buttonLeft {
  padding-left: 10px;
  padding-right: 10px;
}

.client-card .completeCheckbox .MuiCheckbox-root {
  padding: 0;
}

.client-card .completeCheckbox .MuiTypography-body1 {
  font-size: 14px;
  font-family: "Avenir-Book";
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0;
}

.client-card .completeCheckbox .MuiListItem-gutters {
  padding-left: 5px;
  padding-right: 5px;
}

.disabled-item {
  color: #c0c0c0;
}

.disabled-item .pv-collapse {
  color: #c0c0c0;
}

.client-card .buttonRight {
  margin-left: 20px;
}

.client-card .action-button {
  text-align: right;
}

.invite-clients {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}

.invite-clients button {
  height: 30px;
  text-transform: none;
  width: auto;
  margin-bottom: 10px;
}

.edit-bidder-invite-button button {
  height: 50px;
  width: 150px;
  text-transform: none;
}

.edit-bidder-invite-button button.no-agent-button {
  width: 180px;
}

.edit-bidder-invite-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.invite-clients button span.MuiButton-label {
  align-items: center;
  justify-content: center;
}

.oldAgentActionButton button {
  text-transform: none;
}

.my-agent-in-profile .pcd-media .profile-media-img {
  width: 100px;
  height: 100px;
}

.my-agent-in-profile .action-button {
  text-align: right;
}

.my-agent-in-profile .action-button .buttonRight {
  display: inline-block;
  margin-top: 10px;
}

.getting-started-title {
  font-weight: bold;
  font-size: 25px;
}

.details-section {
  background: #f2f5f7;
  padding-top: 15px;
  padding-bottom: 15px;
}

.getting-started-summary {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding-bottom: 15px;
}

.video_player > div {
  margin-left: auto;
}

.invite-clients button.no-agent-button {
  padding-top: 5px;
  margin-right: 10px;
}
.invite-clients .primButton .MuiButtonBase-root {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0 16px;
}

.buyer-verf .contact-us {
  color: var(--primColor);
  text-decoration: underline;
}
.acc-not-vefr p {
  margin: 0;
  border: 1px solid #fb0000;
  padding: 0.5rem 1rem;
  font-size: 13px;
  color: #fb0000;
  width: fit-content;
  padding: 5px 40px;
}
.acc-vefr {
  border: 1px solid #10c26d;
  padding: 0 1rem;
  font-size: 13px;
  color: #10c26d;
  padding: 5px 40px;
}
.acc-vefr p {
  margin: 0;
}
.acc-vefr p .verfied-link {
  text-decoration: underline !important;
  color: #10c26d;
}
.agent-verfied-link {
  color: #10c26d;
}

.agent-verfied-link span {
  text-decoration: underline !important;
  cursor: pointer;
}

.acc-vefr p .verfied-link:hover {
  cursor: pointer;
}
.inv-agnt {
  display: flex;
  justify-content: space-between;
}
.inv-agnt-btns {
  display: flex;
}
.inv-agnt-btns .primButton .MuiButtonBase-root .MuiButton-label {
  font-size: 12px;
  text-transform: none;
}
.inv-agnt-btns .primButton:nth-child(2) .MuiButtonBase-root {
  color: var(--primColor);
  background-color: transparent;
}

.inv-agnt-btns .primButton:nth-child(2) {
  margin-left: 2rem;
}

.inv-agnt-btns .primButton {
  width: 150px;
}

.search-agnt {
  width: 390px;
}
.search-agnt p {
  margin: 0 1rem;
  font-size: 13px;
}
.agnt-pend {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.invite-form-component {
  width: 80%;
}

.finance-form-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.finance-form-section .top-section {
  width: 100%;
}

.finance-form-section .left-section {
  width: 50%;
}

.finance-form-section .right-section {
  width: 50%;
  text-align: right;
}

.finance-form-section .right-section .item p:first-child,
.finance-form-section .left-section .item p:first-child {
  font-size: 17px;
  font-weight: 700;
}

.finance-form-section .right-section .item span,
.finance-form-section .left-section .item span {
  color: var(--primColor);
  text-decoration: underline;
}

.toggle-switch .MuiToggleButtonGroup-root {
  border: 5px solid rgb(249, 245, 255);
  background: rgb(249, 245, 255);
  border-radius: 25px;
}

.toggle-switch .MuiToggleButtonGroup-root button {
  border: none;
  text-transform: none;
  background: rgb(249, 245, 255);
  width: 180px;
}

.toggle-switch .MuiToggleButtonGroup-root .mui-ripple-circle {
  display: none;
}

.toggle-switch .MuiToggleButtonGroup-root button:focus {
  outline: none;
  box-shadow: none;
}

.toggle-switch .MuiToggleButtonGroup-root button.Mui-selected {
  background: #fff;
  color: var(--primColor);
  border-radius: 25px;
}

.finance-form-section .auth-form-wrapper {
  width: 372px;
}

.finance-form-section .question-section {
  width: 330px;
}

.finance-form-section .finance-button-section {
  display: flex;
  justify-content: flex-end;
}

.finance-form-section .finance-update-submit button,
.finance-form-section .finance-update-cancel button {
  text-transform: none;
}

.finance-form-section .left-section .uploaded-files-box {
  width: 100%;
  padding: 15px;
}

.finance-form-section .finance-update-cancel button {
  background: #fff;
  border-color: var(--primColor);
  color: var(--primColor);
}

.finance-form-section .finance-update-cancel button:hover {
  background: var(--primColor);
  color: white;
}

.finance-form-section .finance-update-cancel {
  margin-left: 5px;
}

.finance-form-section .finance-checkboxes {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.finance-form-section .finance-checkboxes div {
  width: 30%;
  margin: 0 0.5rem;
}

.display-financial-information {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.finance-step-block {
  padding-right: 0px;
}

.display-financial-information .top-section,
.display-financial-information .bottom-section {
  width: 100%;
  display: flex;
}

.display-financial-information .top-section {
  flex-wrap: wrap;
}

.display-financial-information .top-section {
  justify-content: space-between;
}

.display-financial-information .top-section span {
  color: gray;
  padding-bottom: 5px;
  display: block;
}

.display-financial-information .top-section div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.display-financial-information .top-section .left-section div {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.display-financial-information .bottom-section {
  justify-content: flex-end;
  margin: 10px 0px;
}

.display-financial-information .bottom-section span,
.finance-form-section .update-billing span {
  color: var(--primColor);
  text-decoration: underline;
  cursor: pointer;
}

.display-financial-information .bottom-section .bottom-section-disabled span,
.finance-form-section .update-billing .bottom-section-disabled span {
  color: gray;
}

.display-financial-information .top-section .left-section {
  width: 50%;
  padding-right: 40px;
}

.display-financial-information .top-section .right-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 50%;
}

.display-financial-information .top-section .right-section .right-section-item {
  width: 50%;
  padding-bottom: 20px;
  padding-right: 5px;
}

.finance-form-section .file-upload-reg .dropzone div {
  width: 100%;
  height: 100px;
  border: 1px dashed black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  border-radius: 8px;
}

.finance-form-section .file-upload-reg .dropzone div .plus-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 100%;
  background: var(--primColor);
  color: #fff;
  padding-top: 4px;
}

.finance-form-section .file-upload-reg .dropzone div .browse-text {
  color: var(--primColor);
  text-decoration: underline;
  cursor: pointer;
}

.finance-form-section .verify-agent-left-section {
  width: 50%;
}

.finance-form-section .verify-agent-right-section {
  padding-left: 50px;
  display: flex;
  align-items: center;
  width: 50%;
  text-align: left !important;
}

.MuiAutocomplete-listbox .MuiAutocomplete-option {
  border-bottom: solid 1px gray;
  font-size: 14px;
}

.MuiAutocomplete-listbox li:last-child {
  color: gray;
  justify-content: center;
}

.MuiAutocomplete-listbox .MuiAutocomplete-option button {
  text-transform: none;
}

.MuiAutocomplete-listbox .MuiAutocomplete-option button:disabled {
  background-color: #fff !important;
  color: gray !important;
  border-color: #fff !important;
}

.finance-form-section .verify-agent-right-section .instruction-group span {
  font-weight: bold;
  font-size: large;
}

.display-financial-information
  .top-section
  .right-section
  .right-section-item
  .fin-email {
  max-width: 160px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.billing-info-step-1 {
  color: var(--primColor);
}

.verification-complete-button {
  width: 200px;
}

.verification-complete-button button {
  text-transform: none;
}

.practice-card h3 {
  font-size: 18px;
}

.practice-card .practice-box {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.practice-card .comment-practice-box {
  padding: 15px;
}

.agnt-pend-main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.agnt-pend-main .right-button-section {
  display: flex;
  align-items: center;
}

.agnt-pend-main .agnt-pend {
  width: 60%;
  padding: 30px 10px;
}
.drag-drop {
  max-width: 150px;
  width: 100%;
  height: 150px;
}

.client-emails {
  display: flex;
  justify-content: space-between;
}
.client-emails .email-box {
  width: 80%;
}
.send-inv {
  margin-top: 2.5rem;
}

.add-clients {
  margin: 1rem 0;
}
.email-box p {
  color: #9faebb;
  font-size: 12px;
  font-family: "Avenir-Medium";
}
.add-clients p {
  font-family: "Avenir-Medium";
}

.add-client-head {
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #9faebb;
  margin-top: 10px;
  font-family: "Avenir-Medium";
  text-align: left;
}
.verify-clients-list .client-avatar img {
  margin: 0 1rem;
}
.verify-clients-list .client-info,
.verify-clients-li.client-card .action-button {
  margin-top: 20px;
}

.client-add-disabled {
  color: #9faebb;
}
.client-info-name .client-info span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 315px;
  display: inline-block;
}
.about-box-mob .pc-margin-bottom {
  max-width: 50%;
}
.db-menu-mobile li a:hover {
  border-left: none;
}
@media (max-width: 834px) {
  .client-emails .email-box {
    width: 70%;
  }
}
@media (max-width: 767px) {
  .pcd-media.prflNwCs .profile-media-img,
  .pcd-media .profile-media-img {
    width: 75px;
    height: 75px;
  }

  .invite-form-component {
    width: 100%;
  }

  .finance-form-section .verify-agent-right-section {
    padding-left: 0px;
  }

  .toggle-switch .MuiToggleButtonGroup-root button {
    width: 150px;
  }

  .mobile-lable .MuiButton-label {
    font-size: 12px;
  }

  .finance-step-block {
    padding-right: 20px;
  }

  .display-financial-information .top-section .left-section {
    margin-bottom: 10px;
  }

  .display-financial-information .top-section .left-section,
  .display-financial-information .top-section .right-section {
    width: 100%;
  }

  .display-financial-information
    .top-section
    .right-section
    .right-section-item {
    width: 100%;
  }

  .inv-agnt-btns .primButton:nth-child(2) {
    margin-left: 5px;
  }

  .inv-agnt-btns .primButton {
    width: 135px;
  }

  .agnt-pend-main .agnt-pend {
    width: 100%;
    margin-bottom: 10px;
  }

  .finance-form-section .left-section {
    width: 100%;
    margin-bottom: 20px;
  }

  .finance-form-section .right-section {
    width: 100%;
    text-align: left;
  }

  .finance-form-section .auth-form-wrapper {
    width: 100%;
  }

  .invite-clients button.no-agent-button {
    margin-right: 0px;
  }

  .my-agent-in-profile .pcd-media .profile-media-img {
    width: 75px;
    height: 75px;
  }

  .client-card .buttonRight {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
  }

  .client-card .buttonRight button,
  .client-card .buttonLeft button {
    width: 100%;
  }

  .client-card .client-avatar .profile-media-img {
    width: 70px;
    height: 70px;
  }

  .client-card .client-info {
    margin-top: 0px;
    text-align: left;
    padding-left: 15px;
  }

  .search-agent
    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .client-card .action-button {
    margin-top: 10px;
    text-align: center;
  }

  .pfrlCardNew .pcd-media.prflNwCs {
    padding: 12px;
    min-height: auto;
  }

  .role-text {
    font-size: 14px;
    line-height: 22px;
    color: #000;
    font-family: "Avenir-Medium";
  }

  .pcd-media.prflNwCs .pcd-name,
  .pcd-name {
    font-size: 18px;
    line-height: 24.59px;
  }

  .pcd-media.prflNwCs .edit {
    float: right;
    margin: -10px -10px 10px;
    z-index: 110;
    cursor: pointer;
  }

  .upload-photo {
    z-index: 2;
    width: 95px;
    height: 95px;
    font-size: 12px;
    line-height: 21px;
  }

  .upload-photo label {
    margin-bottom: 0;
  }

  .pcd-media,
  .about-box {
    padding: 12px;
  }

  .about-box {
    margin-top: 15px;
  }

  .pcd-media .media-body {
    padding-left: 15px;
  }

  .rp-cbtn {
    width: 100%;
    margin: 15px auto 0;
    font-size: 18px;
    font-weight: 400;
    font-family: "Avenir-Medium";
  }

  .video_player {
    margin-left: 0;
  }

  .video_player > div {
    margin-left: 0;
  }

  .gtStActBtn .myclients-buttons {
    flex-wrap: wrap;
  }

  .gtStActBtn .myclients-buttons div {
    width: 100%;
    /* margin-left: 0 !important; */
  }

  .gtStActBtn .myclients-buttons .expand,
  .gtStActBtn .myclients-buttons .pv-collapse {
    text-align: left;
  }
  .client-emails .email-box {
    width: 75%;
  }
}

@media (max-width: 600px) {
  .client-emails .email-box {
    width: 65%;
  }
}

@media (max-width: 480px) {
  .client-emails .email-box {
    width: 100%;
  }
  .client-emails {
    flex-direction: column;
  }
  .send-inv .primButton {
    width: 180px;
    margin: 0 auto;
  }
  .send-inv {
    margin-top: 0;
  }
}
@media (max-width: 360px) {
  .role-text {
    font-size: 12px;
  }
  .finance-button-section {
    flex-direction: column;
  }
  .finance-form-section .finance-update-cancel {
    margin-left: 0;
    margin-top: 10px;
  }

  .client-card .myclients-buttons {
    flex-direction: column;
  }
  .gtStActBtn .myclients-buttons div {
    width: max-content;
  }
}
