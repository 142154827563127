.primButton.small .MuiButton-containedPrimary {
  height: 40px !important;
}

.oldAgentActionButtons div,
.newAgentActionButton div {
  display: inline-block;
  margin-left: 10px;
}

.no-decoration button {
  text-transform: none;
  font-size: 13px;
}

.oldAgentActionButtons,
.newAgentActionButton {
  text-align: right;
  height: 50px;
  padding-top: 10px;
}

.newAgentSection {
  margin-top: 20px;
}

.preferred-contact-method,
.agentProfileImageRow {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.contactAgentActionButton {
  text-align: center;
  height: 50px;
  padding-top: 10px;
}

.navigate-to-myagent {
  cursor: pointer;
  color: var(--primColor);
  text-decoration: underline;
}

.contactAgentActionButton div {
  display: inline-block;
  margin-left: 10px;
}

.preferred-contact-method .MuiTypography-body1 {
  font-family: "Avenir-Book";
  font-size: 14px;
}

/* .preferred-contact-method {
  height: 50px;
} */

@media (max-width: 768px) {
  .oldAgentActionButtons div,
  .newAgentActionButton div {
    margin-left: 5px;
  }

  .preferred-contact-method {
    display: inline-block;
    margin-top: 10px;
  }
}
